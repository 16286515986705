<template>
    <Toast />
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
                <img src="images/zlto_v2_black_logo.png" alt="Zlto logo" class="mb-3" style="width:100px; height:100%;">
            </div>
            <div class="col-12 xl:col-6"
                style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, #010323, rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4"
                    style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="text-center mb-5">
                        <span class="text-600 font-medium">Sign in to continue</span>
                    </div>

                    <div class="w-full md:w-10 mx-auto">
                        <form @submit="checkForm">
                            <label for="email1" class="block text-900 text-xl font-medium mb-2">Email</label>
                            <InputText id="email1" v-model="email" type="text" class="w-full mb-3" placeholder="Email"
                                style="padding:1rem;" />

                            <label for="password1" class="block text-900 font-medium text-xl mb-2">Password</label>
                            <Password id="password1" :feedback="false" v-model="password" placeholder="Password"
                                :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem">
                            </Password>

                            <div class="flex align-items-center justify-content-between mb-5">
                            </div>
                            <Button :disabled="submitted" style="background-color: #010323;border-color: #010323;"
                                type="submit" label="Sign In" class="w-full p-3 text-xl"></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
export default {
    data() {
        return {
            email: null,
            password: null,
            checked: false,
            submitted: false,
            errors: [],
        }
    },

    methods: {

        checkForm(e) {
            if (this.email && this.password) {
                this.handleLogin(e)
                // return true;
            }

            this.errors = [];

            if (!this.email) {
                this.showError('Email required.')
                this.errors.push('Email required.');
            }
            if (!this.password) {
                this.showError('Password required.')
                this.errors.push('Password required.');
            }

            e.preventDefault();
        },

        handleLogin(user) {
            user.preventDefault();
            console.log(user)
            this.submitted = true
            this.showInfo("Logging in...")
            const user_detail = {
                username: this.email,
                password: this.password
            }
            console.log(user_detail)
            this.$store.dispatch("auth/login", user_detail).then(
                () => {
                    this.$router.push("/");
                },
                (error) => {
                    this.submitted = false
                    this.showError(error.response?.data?.detail)
                    console.log(error.response?.data?.detail)
                }
            );
        },

        showSuccess(message) {
            this.$toast.add({ severity: 'success', summary: 'Success', detail: message, life: 3000 });
        },
        showInfo(message) {
            this.$toast.add({ severity: 'info', summary: 'Info', detail: message, life: 3000 });
        },
        showWarn(message) {
            this.$toast.add({ severity: 'warn', summary: 'Warn', detail: message, life: 3000 });
        },
        showError(message) {
            this.$toast.add({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
        },


    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    }
}
</script>

<style scoped>
.pi-eye {
    transform: scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform: scale(1.6);
    margin-right: 1rem;
}
</style>